import { Modal } from 'antd';
import axios from 'axios';
import React, { useState } from 'react'
import config from '../../config';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../Loader';
import HeaderMarketPlace from '../../components/shared/headers/HeaderMarketPlace';
import HeaderMobile from '../../components/shared/headers/HeaderMobile';

import CryptoJS from 'crypto-js';
import swal from 'sweetalert';

const PaymentReceiptUpload = () => {
    const [receiptModal, setReceiptModal] = useState(true);
    const [payment_receipt, setPaymentReceipt] = useState(false);
    const [loading, setLoading] = useState(null);

    const navigate = useNavigate()

    const { order_id, user_id } = useParams()

    const secretKey = "WhansonSecretGroup";

    const decryptData = (ciphertext) => {
        // Decrypt data using AES algorithm and the secret key
        const safeCiphertext = ciphertext.replace(/w_h/g, '/');

        const bytes = CryptoJS.AES.decrypt(safeCiphertext, secretKey);
        return bytes.toString(CryptoJS.enc.Utf8); // Convert bytes to string
    };

    const handleUploadReceipt = async (e) => {
        e.preventDefault();
        try {
            setLoading(true)
            const formData = new FormData()
            formData.append("order_id", decryptData(order_id))
            formData.append("payment_receipt", payment_receipt)
            formData.append("user_id", decryptData(user_id));

            const res = await axios.post(`${config.apiUrl}/uploadReceipt`, formData)

            swal("", "Receipt Uploaded Successfully", "success").then(() => {
                navigate(`${config.appUrl}`)
            })

        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div>
            {loading && <Loader />}
            <HeaderMarketPlace />
            <HeaderMobile />
            {/* Upload a receipt Popup */}
            <Modal
                open={receiptModal}
                title="Upload Payment Receipt"
                centered
                onOk={() => setReceiptModal(false)}
                // onCancel={() => setReceiptModal(false)}
                footer={<div className="footer-none"></div>}
                maskClosable={false}
                width={400}
            >
                <div>
                    <form onSubmit={handleUploadReceipt}>
                        <div className="form-group my-4">
                            <h4>Upload Payment Receipt</h4>
                            <input type="file" className="ant-input py-3"
                                onChange={(e) => setPaymentReceipt(e.target.files[0])} required />
                        </div>

                        <button className="ps-btn bg-blue color-white mt-5">Upload Receipt</button>
                    </form>
                </div>
            </Modal>
        </div>
    )
}

export default PaymentReceiptUpload