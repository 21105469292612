const config = {
  // apiUrl: "https://demo.w-hanson.co.uk/backend/api/v2",
  apiUrl: "https://w-hanson.co.uk/backend/api/v2",

  appUrl: "/",
  stripKey: "pk_live_51LBAz6JaXtAdPEaby8WP5UjKwPG8CKHF0kCCCfexzixAXhGUVhJa1iUclgI5wJtQQIKFk9DaAo445tuxYQtjJys300mWgRMOWv",
  // stripKey: "pk_test_51O9iUSSAu4SQbYbwKYY8lV7aGKR1h7xAZKxjBY8NAK3CtawTmoS5xb5CrN9Vxp5wbaectWNviMVnANiCae1ANvgg00VwGp0z2H",
  imageUrl: "https://w-hanson.co.uk/backend/storage/app/public",
  // websiteUrl: "https://w-hanson.co.uk",
  websiteUrl: "https://w-hanson.co.uk",
};

export default config;