import React, { useEffect, useRef, useState } from "react";
import BreadCrumb from "../../components/elements/BreadCrumb";
import PageContainer from "../../components/layouts/PageContainer";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import HeaderMarketPlace from "../../components/shared/headers/HeaderMarketPlace";
import HeaderMobile from "../../components/shared/headers/HeaderMobile";
import FooterMarketPlace2 from "../../components/shared/footers/FooterMarketPlace2";
import Slider from "react-slick";
import NextArrow from "../../components/elements/carousel/NextArrow";
import PrevArrow from "../../components/elements/carousel/PrevArrow";
import Lightbox from "react-image-lightbox";
import { Modal, Switch, Tabs, notification } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import ProductDescription from "./ProductDescription";
import ProductReview from "./ProductReview";
import axios from "axios";
import Loader from "../Loader";
import NoImg from "../../assets/static/img/w-logo.jpg";
import swal from "sweetalert";
import config from "../../config";

const ProductDetail = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (
  //     !localStorage.getItem("auth_user")
  //   ) {
  //     navigate("/login");
  //   }
  // }, []);

  const headers = (
    <>
      <HeaderMarketPlace />
      <HeaderMobile />
    </>
  );
  const footer = <FooterMarketPlace2 />;

  useEffect(() => {
    fetchDryWallProduc();
  }, [location]);

  const [isLoading, setLoading] = useState(false);
  const [product_detail, setProductDetail] = useState([]);
  const [related_product, setRelated] = useState([]);
  const [productImages, setProductImages] = useState([]);
  const [loginModal, setLoginModal] = useState(false);
  const [recenr_Product, setRecentProduct] = useState([]);

  const fetchDryWallProduc = async () => {
    setLoading(true);
    try {
      await axios({
        method: "get",
        url: `${config.apiUrl}/productDetail/${params.slug}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => {
        setRecentProduct(res.data?.details?.recent_products?.data);
        setProductDetail(res.data.details);
        setRelated(res.data.details?.related_products?.data);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const [quantity, setQuantity] = useState(1);

  function handleIncreaseItemQty(e) {
    e.preventDefault();
    setQuantity(quantity + 1);
  }

  function handleDecreaseItemQty(e) {
    e.preventDefault();
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  }

  useEffect(() => {
    let images = [];
    if (product_detail && product_detail?.pics?.length > 0) {
      product_detail?.pics?.map((item) => {
        images.push(item);
      });
      setProductImages(images);
    } else {
      setProductImages([]);
    }
    setGallery(galleryCarousel.current);
    setVariant(variantCarousel.current);
  }, [product_detail]);

  const galleryCarousel = useRef(null);
  const variantCarousel = useRef(null);
  const [gallery, setGallery] = useState(null);
  const [variant, setVariant] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(1);

  const handleOpenLightbox = (imageIndex) => {
    setPhotoIndex(imageIndex);
    setIsOpen(true);
  };

  const vertical = true;

  const gallerySetting = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  const variantSetting = {
    prevArrow: (
      <a className={`slick-arrow slick-next w-100 text-center`}>
        <i className="icon-chevron-up text-center ml-25"></i>
      </a>
    ),
    nextArrow: (
      <a className={`slick-arrow slick-next w-100 text-center`}>
        <i className="icon-chevron-down text-center ml-25"></i>
      </a>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          dots: false,
          arrows: false,
          vertical: false,
          infinite: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          dots: false,
          arrows: false,
          vertical: false,
          infinite: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          dots: false,
          arrows: false,
          vertical: false,
          infinite: false,
        },
      },
    ],
  };

  let Badge;
  if (product_detail?.discount != null) {
    Badge = (
      <div
        className="ps-product__badge bd-detail-bagde"
        style={{ zIndex: "99" }}
      >
        {product_detail?.discount} %
      </div>
    );
  } else if (product_detail?.spot_deal != null) {
    Badge = (
      <div
        className="ps-product__badge bd-detail-bagde"
        style={{ zIndex: "99" }}
      >
        {product_detail?.spot_deal} %
      </div>
    );
  } else if (
    product_detail?.discount == null &&
    product_detail?.spot_deal == null
  ) {
    Badge = <></>;
  }

  //Views
  let lightboxView, variantCarouselView, imagesView, galleryImagesView;
  if (productImages?.length > 0 && productImages?.length != 0) {
    imagesView = productImages?.map((item) => (
      <div className="item" key={item}>
        <img src={item} alt="No img" className="" height="56" width="56" />
      </div>
    ));
    galleryImagesView = productImages?.map((item, index) => (
      <div
        className="item d-flex justify-content-center position-relative"
        key={item}
      >
        {Badge}
        <a
          href="#"
          className="col-10"
          onClick={(e) => handleOpenLightbox(index)}
        >
          <img src={item} alt="No img" className="" height="340" />
        </a>
      </div>
    ));
  } else {
    imagesView = (
      <div className="item">
        <img src={NoImg} alt="No img" className="" height="56" width="56" />
      </div>
    );

    galleryImagesView = (
      <div className="item d-flex justify-content-center">
        <a href="#" className="col-10">
          <img src={NoImg} alt="No img" className="" />
        </a>
      </div>
    );
  }
  if (vertical) {
    variantCarouselView = (
      <>
        {productImages?.length == 1 || productImages?.length == 0 ? (
          <Slider
            asNavFor={gallery}
            ref={(slider) => (variantCarousel.current = slider)}
            swipeToSlide={true}
            arrows={true}
            slidesToShow={1}
            vertical={true}
            infinite={true}
            focusOnSelect={true}
            {...variantSetting}
            className="ps-product__variants"
          >
            {imagesView}
          </Slider>
        ) : productImages?.length == 2 ? (
          <Slider
            asNavFor={gallery}
            ref={(slider) => (variantCarousel.current = slider)}
            swipeToSlide={true}
            arrows={true}
            slidesToShow={2}
            vertical={true}
            infinite={true}
            focusOnSelect={true}
            {...variantSetting}
            className="ps-product__variants"
          >
            {imagesView}
          </Slider>
        ) : (
          <Slider
            asNavFor={gallery}
            ref={(slider) => (variantCarousel.current = slider)}
            swipeToSlide={true}
            arrows={true}
            slidesToShow={3}
            vertical={true}
            infinite={true}
            focusOnSelect={true}
            {...variantSetting}
            className="ps-product__variants"
          >
            {imagesView}
          </Slider>
        )}
      </>
    );
  } else {
    variantCarouselView = (
      <Slider
        asNavFor={gallery}
        ref={(slider) => (variantCarousel.current = slider)}
        swipeToSlide={true}
        arrows={false}
        slidesToShow={3}
        vertical={false}
        centered={true}
        infinite={false}
        focusOnSelect={true}
        className="ps-product__variants"
      >
        {imagesView}
      </Slider>
    );
  }
  if (isOpen) {
    lightboxView = (
      <Lightbox
        mainSrc={product_detail?.pics[photoIndex]}
        nextSrc={
          product_detail?.pics[(photoIndex + 1) % product_detail?.pics?.length]
        }
        prevSrc={
          product_detail?.pics[
          (photoIndex + product_detail?.pics?.length - 1) %
          product_detail?.pics?.length
          ]
        }
        onCloseRequest={() => {
          setIsOpen(false);
        }}
        onMovePrevRequest={() => {
          setPhotoIndex(
            (photoIndex + product_detail?.pics?.length - 1) %
            product_detail?.pics?.length
          );
        }}
        onMoveNextRequest={() => {
          setPhotoIndex((photoIndex + 1) % product_detail?.pics?.length);
        }}
      />
    );
  }

  const carouselFullwidth = {
    dots: false,
    infinite: true,
    speed: 750,
    slidesToShow: 4,
    slidesToScroll: 2,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  const breadCrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Product Detail",
      url: "/",
    },
    {
      text: product_detail?.name,
    },
  ];

  const [open, setOpen] = useState(false);

  const stars = [];
  for (let i = 1; i <= 5; i++) {
    const className =
      i <= product_detail?.reviews?.length ? "fa fa-star" : "fa fa-star-o ";
    stars.push(
      <i className={className} style={{ paddingRight: "5px" }} key={i}></i>
    );
  }

  const [height, setHeight] = useState("");
  const [width, setWidth] = useState("");
  const [length, setLength] = useState("");
  const [width_array, setWidthArray] = useState([]);
  const [total_price, setTotalPrice] = useState("");
  const [available_qty, setAvailabelQty] = useState("");
  const [negotiate_terms, setNegotiateTerms] = useState(false);

  const mainArray = product_detail?.stocks;
  const heightArray = product_detail?.stocks;

  const uniqueHeights = [];
  const uniqueWidths = [];
  const uniqueHeightSet = new Set();
  const uniqueWidthsSet = new Set();

  if (heightArray) {
    heightArray.forEach((item) => {
      if (!uniqueHeightSet.has(item.height)) {
        uniqueHeightSet.add(item.height);
        uniqueHeights.push(item);
      }
    });
  }
  if (heightArray) {
    heightArray.forEach((item) => {
      if (!uniqueWidthsSet.has(item.width)) {
        uniqueWidthsSet.add(item.width);
        uniqueWidths.push(item);
      }
    });
  }

  let Discount = 0;
  if (product_detail?.discount != null) {
    Discount = product_detail?.discount;
  } else if (product_detail?.spot_deal != null) {
    Discount = product_detail?.spot_deal;
  } else {
    Discount = 0;
  }

  const [main_total, setMainTotal] = useState("");

  useEffect(() => {
    if (product_detail?.stocks?.length >= 1) {
      setWidth(product_detail?.stocks[0].width);
      setHeight(product_detail?.stocks[0].height);
      const result = product_detail?.stocks?.find(
        (item) =>
          item.width == product_detail?.stocks[0].width &&
          item.height === product_detail?.stocks[0].height
      );
      const total = result ? result.price : null;
      if (Discount != 0) {
        const main_discount = (total * Discount) / 100;
        setTotalPrice(parseFloat(total) - parseFloat(main_discount));
      } else {
        setTotalPrice(total);
      }
      setMainTotal(total);

      const WidthArray = product_detail?.stocks?.filter(
        (obj) => obj.height == product_detail?.stocks[0].height
      );
      setWidthArray(WidthArray);

      if (
        product_detail?.length_variation != undefined &&
        product_detail?.brand?.name != "Standard"
      ) {
        setLength(product_detail?.length_variation[0].id);
      } else {
        setLength(1);
      }
      // setTotalPrice(total)
      setAvailabelQty(result.qty);
    } else {
      setTotalPrice(product_detail?.unit_price)
      setLength(1);
    }
  }, [product_detail]);

  const onRedirect = () => {
    setOpen(false);
    if (
      !localStorage.getItem("auth_id") &&
      !localStorage.getItem("auth_name")
    ) {
      setLoginModal(true);
    } else {
      navigate("/checkout");
    }
  };

  const [itemsInStorage, setItemsInStorage] = useState(
    JSON.parse(localStorage.getItem("items")) || []
  );

  const [vat_incl, setVatIncl] = useState(false);
  const totalPrice = total_price
    ? (total_price * parseFloat(length) * parseFloat(quantity))?.toFixed(2)
    : 0;

  const LengthArray =
    product_detail?.lengths != null && product_detail?.lengths != "null"
      ? JSON.parse(product_detail?.lengths)
      : [];
  const containsStatus1 = LengthArray?.some((item) => item.status === 1);
  let OfferLength;
  if (containsStatus1 == true) {
    OfferLength = LengthArray.filter((obj) => obj.status == 1)[0]?.id;
  }

  const [slash, setSlash] = useState("");

  useEffect(() => {
    if (params.slug == "kd-c16-eased-edged-sawn-regd--treated--sw-jQRGe") {
      // if (height == 47 && width == 100 && length == 3) {
      //   setTotalPrice(1.40);
      //   setSlash(1.51);
      // }
      // else if (height == 47 && width == 100 && length == 3.6) {
      //   setTotalPrice(1.40);
      //   setSlash(1.44);
      // } else if (height == 47 && width == 100 && length == 4.2) {
      //   setTotalPrice(1.40);
      //   setSlash(1.51);
      // } else if (height == 47 && width == 100 && length == 4.8) {
      //   setTotalPrice(1.40);
      //   setSlash(1.51);
      // }
      // else {
      //   setSlash("");
      // }
      if (height == 47 && width == 100 && length == 3) {
        setTotalPrice(1.5);
        setSlash(1.51);
      } else if (height == 47 && width == 150 && length == 3.6) {
        setTotalPrice(2.31);
        setSlash(2.42);
      } else if (height == 47 && width == 200 && length == 4.8) {
        setTotalPrice(3.13);
        setSlash(3.22);
      } else {
        setSlash("");
      }
    }
  }, [height, width, length]);

  // Add To Cart Function

  const handleAddToCart = (message) => {
    const newArray = [product_detail];
    const mainArray = newArray.map((items) => ({
      id: items.id,
      name: items.name,
      thumbnail_image: items?.pics ? items?.pics[0] : null,
      price: (total_price * parseFloat(length))?.toFixed(2),
      qty: quantity,
      // ...(product_detail?.subcategory_id === 306 && {
      width: width,
      length: length,
      height: height,
      // }),
      type: product_detail?.brand?.name,
      slug: items.slug,
      negotiate: localStorage.getItem("cart_id") !== null,
      zip_status: 0,
    }));
    let updatedItems;

    const checkMatchingObject = (mainArray) => {
      if (!Array.isArray(mainArray)) {
        return false;
      }
      return mainArray?.some(
        (mainObj) =>
          mainObj.height == height &&
          mainObj.width == width &&
          mainObj.length == length
      );
    };

    if (!itemsInStorage.some((item) => item.id === product_detail?.id)) {
      updatedItems = [...itemsInStorage, mainArray[0]];
      localStorage.setItem("items", JSON.stringify(updatedItems));
      setItemsInStorage(updatedItems);
      notification.open({
        message: (
          <div className="d-flex">
            <div className="pr-20">
              <i className="fa fa-check" style={{ color: "#4bb543" }}></i>
            </div>
            <div>Product Added to Cart</div>
          </div>
        ),
        description: "You can view it in the cart.",
      });
    } else if (
      itemsInStorage.some(
        (item) =>
          item.id === product_detail?.id &&
          checkMatchingObject(JSON.parse(localStorage.getItem("items")))
      ) == false
    ) {
      updatedItems = [...itemsInStorage, mainArray[0]];
      localStorage.setItem("items", JSON.stringify(updatedItems));
      setItemsInStorage(updatedItems);
      notification.open({
        message: (
          <div className="d-flex">
            <div className="pr-20">
              <i className="fa fa-check" style={{ color: "#4bb543" }}></i>
            </div>
            <div>Product Added to Cart</div>
          </div>
        ),
        description: "You can view it in the cart.",
      });
    } else if (product_detail?.subcategory_id === 306) {
      swal({
        icon: "warning",
        text: "Please allow extended lead time on this product of 2-4 weeks",
      }).then(() => {
        updatedItems = [...itemsInStorage, mainArray[0]];
        localStorage.setItem("items", JSON.stringify(updatedItems));
        setItemsInStorage(updatedItems);
        notification.open({
          message: (
            <div className="d-flex">
              <div className="pr-20">
                <i className="fa fa-check" style={{ color: "#4bb543" }}></i>
              </div>
              <div>Product Added to Cart</div>
            </div>
          ),
          description: "You can view it in the cart.",
        });
      });
    } else {
      notification.open({
        message: (
          <div className="d-flex">
            <div className="pr-20">
              <i
                className="fa fa-exclamation-circle text-danger"
                style={{ fontSize: "20px" }}
              ></i>
            </div>
            <div>Product Already Added</div>
          </div>
        ),
        description: "You can increase its Quantity",
      });
    }
    if (message == "negotiate") {
      navigate("/shopping-cart");
    }
  };

  const seenWidths = new Set();
  return (
    <PageContainer header={headers} footer={footer}>
      {isLoading && <Loader />}
      <BreadCrumb breacrumb={breadCrumb} layout="fullwidth" />
      <div className="ps-page--product mt-60">
        <div className="container">
          <div className="ps-page__container bd-top-height">
            <div className="ps-page__left " style={{ maxWidth: "100%" }}>
              <div className="ps-product--detail ps-product--fullwidth ">
                <div className="ps-product__header">
                  <div
                    className="ps-product__thumbnail"
                    data-vertical={vertical ? "true" : "false"}
                  >
                    <figure>
                      <div className="ps-wrapper">
                        <Slider
                          {...gallerySetting}
                          ref={(slider) => (galleryCarousel.current = slider)}
                          asNavFor={variant}
                          className="ps-product__gallery ps-carousel inside"
                        >
                          {galleryImagesView}
                        </Slider>
                      </div>
                    </figure>
                    {variantCarouselView}
                    {lightboxView}
                  </div>

                  <div className="ps-product__info">
                    <header>
                      <h1>{product_detail?.name}</h1>
                      <div className="ps-product__meta">
                        <p>
                          Type:
                          <a className="ml-2 text-capitalize">
                            {product_detail?.brand?.name}
                          </a>
                        </p>
                        <div className="d-flex justify-content-between w-100 mobile-flex">
                          <div className="ps-product__rating">
                            {stars}
                            <span>
                              ({product_detail?.reviews?.length} review)
                            </span>
                          </div>
                          <div>
                            {" "}
                            <span
                              className="text-danger "
                              style={{ fontSize: "12px" }}
                            >
                              Web prices may vary to in store
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="border-bottom-line mb-25">
                        <div className="d-flex">
                          <h5
                            className="ps-product__price mb-0"
                            style={{ fontSize: "20px" }}
                          >
                            Price :
                            {Discount != 0 ? (
                              <>
                                {product_detail?.brand?.name == "Linear" ? (
                                  <>
                                    {params.slug ==
                                      "kd-c16-eased-edged-sawn-regd--treated--sw-jQRGe" ? (
                                      <s
                                        style={{
                                          paddingRight: "20px",
                                          textDecoration: "strike-out",
                                        }}
                                      >
                                        {(height == 47 &&
                                          width == 100 &&
                                          length == 3) ||
                                          (height == 47 &&
                                            width == 150 &&
                                            length == 3.6) ||
                                          (height == 47 &&
                                            width == 200 &&
                                            length == 4.8)
                                          ? `£${slash}`
                                          : slash?.length != 0
                                            ? `£${parseFloat(main_total).toFixed(
                                              2
                                            )}`
                                            : null}
                                      </s>
                                    ) : (
                                      <s
                                        style={{
                                          paddingRight: "20px",
                                          textDecoration: "strike-out",
                                        }}
                                      >
                                        £{parseFloat(main_total).toFixed(2)}
                                      </s>
                                    )}
                                  </>
                                ) : (
                                  <s
                                    style={{
                                      paddingRight: "20px",
                                      textDecoration: "strike-out",
                                    }}
                                  >
                                    £{parseFloat(main_total).toFixed(2)}
                                  </s>
                                )}
                              </>
                            ) : null}
                            £
                            {parseFloat(total_price).toFixed(2)}{" "}
                            {product_detail?.brand?.name == "Linear" ? (
                              <>
                                / <span style={{ fontSize: "16px" }}>m</span>{" "}
                              </>
                            ) : null}
                          </h5>
                        </div>
                      </div>
                      {product_detail?.brand?.name == "Linear" ? (
                        <>
                          <div className="d-flex justify-space-between w-100 border-bottom-line mb-25 mobile-flex">
                            <div className="d-flex col-4 pl-0">
                              <h3 className=" pl-0 pr-10">Height : </h3>
                              <select
                                className="form-select col-6"
                                data-placeholder="Sort Items"
                                onChange={(e) => {
                                  setWidthArray(
                                    mainArray.filter(
                                      (item) => item.height == e.target.value
                                    )
                                  );
                                  setHeight(e.target.value);
                                  setWidth(
                                    mainArray.filter(
                                      (item) => item.height == e.target.value
                                    )[0].width
                                  );
                                  setMainTotal(
                                    mainArray.filter(
                                      (item) => item.height == e.target.value
                                    )[0]?.price
                                  );

                                  if (Discount != 0) {
                                    if (OfferLength == length) {
                                      const main_discount =
                                        (mainArray.filter(
                                          (item) =>
                                            item.height == e.target.value
                                        )[0]?.price *
                                          Discount) /
                                        100;
                                      setTotalPrice(
                                        parseFloat(
                                          mainArray.filter(
                                            (item) =>
                                              item.height == e.target.value
                                          )[0]?.price
                                        ) - parseFloat(main_discount)
                                      );
                                    } else {
                                      setTotalPrice(
                                        mainArray.filter(
                                          (item) =>
                                            item.height == e.target.value
                                        )[0]?.price
                                      );
                                    }
                                  } else {
                                    setTotalPrice(
                                      mainArray.filter(
                                        (item) => item.height == e.target.value
                                      )[0]?.price
                                    );
                                  }
                                  setAvailabelQty(
                                    mainArray.filter(
                                      (item) => item.height == e.target.value
                                    )[0]?.qty
                                  );
                                }}
                              >
                                {uniqueHeights?.map((items, i) => {
                                  return (
                                    <option key={i} value={items.height}>
                                      {items.height} mm
                                    </option>
                                  );
                                })}
                              </select>
                            </div>

                            <div className="d-flex col-4 pl-0">
                              <h3 className=" pl-0 pr-10">Width : </h3>
                              <select
                                className="form-select col-6"
                                data-placeholder="Sort Items"
                                value={width}
                                onChange={(e) => {
                                  setWidth(e.target.value);
                                  const result = mainArray?.find(
                                    (item) =>
                                      item.height == height &&
                                      item.width == e.target.value
                                  );
                                  const price = result ? result?.price : null;
                                  setMainTotal(price);

                                  if (Discount != 0) {
                                    if (OfferLength == length) {
                                      const main_discount =
                                        (parseFloat(price) * Discount) / 100;
                                      setTotalPrice(
                                        parseFloat(price) -
                                        parseFloat(main_discount)
                                      );
                                    } else {
                                      setTotalPrice(price);
                                    }
                                  } else {
                                    setTotalPrice(price);
                                  }
                                  setAvailabelQty(result.qty);
                                }}
                              >
                                {width_array?.map((items, i) => {
                                  if (!seenWidths.has(items.width)) {
                                    seenWidths.add(items.width);
                                    return (
                                      <option value={items.width} key={i}>
                                        {items.width} mm
                                      </option>
                                    );
                                  }
                                  return null;
                                })}
                              </select>
                            </div>
                            {product_detail?.length_variation != undefined ? (
                              <div className="d-flex col-4 pl-0">
                                <h3 className=" pl-0 pr-10">Length : </h3>
                                <select
                                  className="form-select col-6"
                                  data-placeholder="Sort Items"
                                  onChange={(e) => {
                                    setLength(e.target.value);
                                    if (OfferLength != e.target.value) {
                                      const mainPrice = mainArray.find(
                                        (item) =>
                                          item.height == height &&
                                          item.width == width
                                      );
                                      setTotalPrice(mainPrice?.price);
                                    } else {
                                      const mainPrice = mainArray.find(
                                        (item) =>
                                          item.height == height &&
                                          item.width == width
                                      );
                                      const DiscountPrice =
                                        (parseFloat(mainPrice?.price) *
                                          Discount) /
                                        100;
                                      setTotalPrice(
                                        mainPrice?.price - DiscountPrice
                                      );
                                    }
                                  }}
                                >
                                  {product_detail?.length_variation?.map(
                                    (items, i) => {
                                      return (
                                        <option key={i} value={items.id}>
                                          {items.value}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                              </div>
                            ) : (
                              <div className="d-flex col-4 pl-0"></div>
                            )}
                          </div>
                        </>
                      ) : product_detail?.length_variation != undefined ? (
                        <div className="d-flex justify-space-between w-100 border-bottom-line mb-25 mobile-flex">
                          <div className="d-flex col-4 pl-0">
                            <h3 className=" pl-0 pr-10">Length : </h3>
                            <select
                              className="form-select col-6"
                              data-placeholder="Sort Items"
                              onChange={(e) => {
                                setLength(e.target.value);
                                if (OfferLength != e.target.value) {
                                  const mainPrice = mainArray.find(
                                    (item) =>
                                      item.height == height &&
                                      item.width == width
                                  );
                                  setTotalPrice(mainPrice?.price);
                                } else {
                                  const mainPrice = mainArray.find(
                                    (item) =>
                                      item.height == height &&
                                      item.width == width
                                  );
                                  const DiscountPrice =
                                    (parseFloat(mainPrice?.price) *
                                      Discount) /
                                    100;
                                  setTotalPrice(
                                    mainPrice?.price - DiscountPrice
                                  );
                                }
                              }}
                            >
                              {product_detail?.length_variation?.map(
                                (items, i) => {
                                  return (
                                    <option key={i} value={items.id}>
                                      {items.value}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                          </div>
                        </div>
                      ) : null}

                      {product_detail?.brand?.name == "Sheet" ? (
                        <div
                          className="border-bottom-line mb-25 d-flex"
                          style={{ paddingBottom: "20px" }}
                        >
                          <h3 className="mb-0">Variants :</h3>
                          <select
                            className="form-select ml-20"
                            data-placeholder="Sort Items"
                            onChange={(e) => {
                              setAvailabelQty(e.target.value);
                            }}
                          >
                            {product_detail?.stocks?.map((items, i) => {
                              return (
                                <option
                                  key={i}
                                  value={items.qty + " " + items.price}
                                >
                                  {items.variant}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      ) : null}
                    </header>
                    <div
                      className="ps-product__shopping align-items-center"
                      style={{ marginBottom: "15px", paddingBottom: "20px" }}
                    >
                      <h3 className="mb-0">Quantity :</h3>
                      <figure>
                        <div className="form-group--number">
                          <button
                            className="up"
                            onClick={(e) => handleIncreaseItemQty(e)}
                          >
                            <i className="fa fa-plus"></i>
                          </button>
                          <button
                            className="down"
                            onClick={(e) => handleDecreaseItemQty(e)}
                          >
                            <i className="fa fa-minus"></i>
                          </button>
                          <input
                            className="form-control"
                            type="text"
                            value={quantity}
                            onChange={(e) =>
                              setQuantity(parseInt(e.target.value))
                            }
                          />
                        </div>
                      </figure>
                    </div>
                    <div className="d-flex align-items-center border-bottom-line justify-space-between mobile-flex">
                      <div className="d-flex mobile-price  mobile-flex  mobile-100-width">
                        <h3
                          className=" mb-0 mobile-width "
                          style={{ fontSize: "24px", fontWeight: "600" }}
                        >
                          Total Price : £
                          {vat_incl == true
                            ? (
                              parseFloat(totalPrice) +
                              (parseFloat(totalPrice) * 20) / 100
                            )?.toFixed(2)
                            : parseFloat(totalPrice).toFixed(2)}
                        </h3>

                        <span className="pl-20">
                          <div className="">
                            <Switch
                              checked={vat_incl}
                              onChange={(e) => setVatIncl(e)}
                            />
                            <span
                              className="pl-10"
                              style={{ fontSize: "12px" }}
                            >
                              {vat_incl == true ? "(INCL VAT)" : "(EXCL VAT)"}
                            </span>
                          </div>
                        </span>
                      </div>
                      <div>
                        <button
                          className={`btn-ligth-blue mr-20 border-0 ${product_detail?.stock_status == 0 ? "bg-danger font-white" : ""}`}
                          onClick={() => {
                            if (localStorage.getItem("cart_id") != null) {
                              setNegotiateTerms(true);
                            } else {
                              handleAddToCart("");
                            }
                          }}
                          disabled={product_detail?.stock_status == 0}
                        >
                          {product_detail?.stock_status == 0 ? "Out of stock" : "Add to Cart"}
                        </button>
                        {product_detail?.stock_status != 0 ? (
                          <button
                            className="btn-blue mr-20 border-0"
                            onClick={() => {
                              if (localStorage.getItem("cart_id") != null) {
                                setNegotiateTerms(true);
                              } else {
                                handleAddToCart("");
                              }
                              setOpen(true);
                            }}
                            disabled={product_detail?.stock_status == 0}
                          >
                            Buy Now
                          </button>
                        ) : null}

                      </div>
                    </div>
                    {containsStatus1 == true ? (
                      <>
                        {slash?.length != 0 ? (
                          <div
                            className="text-danger"
                            style={{ fontSize: "13px" }}
                          >
                            * This Offers will only be applied on particular
                            Variation
                          </div>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-30 mb-90 mobile-wrap">
            <div className="col-3 mobile-width">
              <section>
                <aside className="widget_same-brand">
                  <h3 className="mb-0" style={{ fontSize: "1.75rem" }}>
                    Recent Products{" "}
                  </h3>
                  <div className="mt-20">
                    {recenr_Product.map((items, i) => {
                      return (
                        <div
                          className="d-flex pd-top-prod align-items-center"
                          key={i}
                        >
                          <div className="col-3">
                            <img
                              src={
                                items.thumbnail_image != null
                                  ? items.thumbnail_image
                                  : NoImg
                              }
                            />
                          </div>
                          <div className="col-9 pl-0 ">
                            <div className="ps-product__container ">
                              <Link
                                to={`/product/${items.slug}`}
                                className="ps-product__vendor text-dark"
                              >
                                {items.name}
                              </Link>
                              <div className="">
                                <div className="ps-product__rating">
                                  {stars}
                                </div>
                                <h4 className="">
                                  <span style={{ color: "#f30" }}>
                                    {" "}
                                    £{parseFloat(items.price).toFixed(2)}
                                  </span>
                                  <del className="ml-10">
                                    {items.stroked_price}
                                  </del>
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </aside>
              </section>
            </div>
            <div className="col-9 mobile-width">
              <div className="ps-product__content ps-tab-root mb-20">
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Description" key="1">
                    <ProductDescription
                      description={product_detail?.description}
                    />
                  </TabPane>
                  <TabPane tab="Reviews" key="2">
                    <ProductReview product_id={product_detail?.id} />
                  </TabPane>
                </Tabs>
              </div>
              <div>
                <div className="ps-section__content">
                  <div className="ps-section__header home4-header-prod mb-20">
                    <h3 style={{ fontWeight: "550" }}>Related Product</h3>
                  </div>
                  <Slider
                    {...carouselFullwidth}
                    className="ps-carousel outside"
                  >
                    {related_product?.map((items, i) => {
                      const stars = [];
                      for (let i = 1; i <= 5; i++) {
                        const className =
                          i <= items.rating ? "fa fa-star" : "fa fa-star-o";
                        stars.push(<i className={className} key={i}></i>);
                      }
                      return (
                        <div key={i}>
                          <div className="ps-product ps-product--inner all-product-card h-340">
                            <div className="ps-product__thumbnail">
                              <Link
                                to={`/product/${items.slug}`}
                                onClick={() => {
                                  window.scrollTo(0, 0);
                                }}
                              >
                                <img
                                  src={
                                    items.thumbnail_image != null
                                      ? items.thumbnail_image
                                      : NoImg
                                  }
                                  height="171"
                                  style={{ objectFit: "cover" }}
                                  alt="No img"
                                />
                              </Link>
                              <ul className="ps-product__actions">
                                <li>
                                  <Link
                                    to={`/product/${items.slug}`}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Add to wishlist"
                                    onClick={() => {
                                      window.scrollTo(0, 0);
                                    }}
                                  >
                                    <i className="icon-eye"></i>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <div className="ps-product__container">
                              <Link
                                className="ps-product__vendor"
                                to={`/product/${items.slug}`}
                                onClick={() => {
                                  window.scrollTo(0, 0);
                                }}
                              >
                                {items.name}
                              </Link>
                              <div className="ps-product__content">
                                <div className="ps-product__rating">
                                  <span className="ps-rating">
                                    {stars} {items.rating}
                                  </span>
                                </div>
                                <p className="ps-product__price sale">
                                  £{parseFloat(items.price).toFixed(2)}
                                  <del className="ml-10">
                                    {items.stroked_price}
                                  </del>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        title="Rules of Refund"
        centered
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={<div className="footer-none"></div>}
      >
        {/* <div className="mb-5 py-2 head_line px-3" style={{ fontSize: "15px" }}>
          We are closed, and orders will not be processed until we return on 2nd January 2025.
        </div> */}
        <ol className="pl-20  mb-30">
          <li className="mb-10">
            Products marked as "non-returnable" on the product detail page
            cannot be returned.
          </li>
          <li className="mb-10">
            No additional information is required to return an eligible order
            unless otherwise noted in the category-specific policy.
          </li>
          <li className="mb-10">
            Products may be eligible for replacement only if the same seller has
            the exact same item in stock.
          </li>
          <li className="mb-10">
            If the replacement request is placed and the seller does not have
            the exact same product in stock, a refund would be issued to you.
          </li>
        </ol>
        <div className="text-center mb-20 ">
          <a
            className="btn-ligth-blue p-4 mr-10"
            onClick={() => setOpen(false)}
          >
            Back to Shopping
          </a>
          <a onClick={onRedirect} className="btn-blue p-4 ml-10">
            Proceed to Checkout
          </a>
        </div>
      </Modal>

      <Modal
        open={loginModal}
        centered
        onOk={() => setLoginModal(false)}
        onCancel={() => setLoginModal(false)}
        footer={<div className="footer-none"></div>}
        header={<></>}
        width={400}
      >
        <div className="text-center mb-20">
          <img src={NoImg} className="" style={{ width: "40%" }} alt="No img" />
        </div>
        <div className="text-center mb-20 ">
          <div className="mb-20">
            <button
              className="btn-ligth-blue p-4  w-100"
              onClick={() => {
                navigate("/users/guest-checkout");
              }}
              style={{ border: "none" }}
            >
              <i
                className="icon-user pr-10"
                style={{ fontSize: "17px", lineHeight: "0" }}
              ></i>
              Continue with Guest User
            </button>
          </div>
          <div>
            <button
              onClick={() => {
                navigate("/users/login");
              }}
              className="btn-blue p-4  w-100"
              style={{ border: "none" }}
            >
              <i
                className="icon-user-lock pr-10"
                style={{ fontSize: "17px", lineHeight: "0" }}
              ></i>
              Continue with Login
            </button>
          </div>
        </div>
      </Modal>

      {/* <Modal
        open={open}
        title="Rules of Refund"
        centered
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={<div className="footer-none"></div>}
      >
        <div className="mb-5 py-2 head_line px-3" style={{ fontSize: "15px" }}>
          We are closed, and orders will not be processed until we return on 2nd January 2025.
        </div>
        <ol className="pl-20  mb-30">
          <li className="mb-10">
            Products marked as "non-returnable" on the product detail page
            cannot be returned.
          </li>
          <li className="mb-10">
            No additional information is required to return an eligible order
            unless otherwise noted in the category-specific policy.
          </li>
          <li className="mb-10">
            Products may be eligible for replacement only if the same seller has
            the exact same item in stock.
          </li>
          <li className="mb-10">
            If the replacement request is placed and the seller does not have
            the exact same product in stock, a refund would be issued to you.
          </li>
        </ol>
        <div className="text-center mb-20 ">
          <a
            className="btn-ligth-blue p-4 mr-10"
            onClick={() => setOpen(false)}
          >
            Back to Shopping
          </a>
          <a onClick={onRedirect} className="btn-blue p-4 ml-10">
            Proceed to Checkout
          </a>
        </div>
      </Modal> */}

      <Modal
        open={negotiate_terms}
        title="Negotiable Terms"
        centered
        onOk={() => setNegotiateTerms(false)}
        onCancel={() => setNegotiateTerms(false)}
        footer={<div className="footer-none"></div>}
      >
        <ol className="pl-20  mb-30">
          <li className="mb-10">
            You can negotiate the cart price only once; if you want to
            Re-Negotiate the price, you'll need to Re-Order the cart.
          </li>
        </ol>
        <div className="text-center mb-20 ">
          <a
            className="btn-ligth-blue p-4 mr-10"
            onClick={() => setNegotiateTerms(false)}
          >
            Back to Cart
          </a>
          <a
            className="btn-blue p-4 ml-10"
            onClick={() => {
              handleAddToCart("negotiate");
            }}
          >
            Proceed for Add to Cart
          </a>
        </div>
      </Modal>
    </PageContainer>
  );
};

export default ProductDetail;
